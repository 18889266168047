import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { registerPayment, getPaymentTx, getPaymentUtxos, getMintStatus, tokenLatest, registerToken } from '../util/api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { sendBtcTransaction } from 'sats-connect';
import FeeRateCard from '../components/FeeRateCard';
import mintImg from '../assets/imgs/mintimg.png';
import logonameImg from '../assets/imgs/logoname.png'; 

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

export default function Mint() {
  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({
    "inscriptionFee": 0,
    "networkFee": 0,
    "serviceFee": 0,
    "royaltyFee": 0,
    "totalFee": 0
  });
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [amount, setAmount] = useState(1);
  const [maximum, setMaximum] = useState(10000);
  const [sum, setSum] = useState(0);
  const [deployLimit, setDeployLimit] = useState(21000);
  const [deployCount, setDeployCount] = useState(0);
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [showMintForm, setShowMintForm] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0.7);
  const [paymentLimit, setPaymentLimit] = useState(0.7);
  const [deductPayment, setDeductPayment] = useState(0.1);
  const [timerText, setTimerText] = useState("00:00:00");
  const referenceDate = new Date("2024-07-03");

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          setFeeValues(calculateFee(customFee, amount, paymentAmount));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          setFeeValues(calculateFee(response[feeRateMode], amount, paymentAmount));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, amount, customFee])

  useEffect(() => {
    let intervalId;

    const updateTimer = async () => {
      try {
        const currentDate = new Date();
        let amount = Math.floor((currentDate - referenceDate) / 1000);
        let day = Math.floor(amount / 60 / 60 /24);
        amount = (day + 1) * 60 * 60 *24 - amount;
        let seconds = amount % 60;
        let temp = Math.floor(amount / 60);
        let minutes = temp % 60;
        temp = Math.floor(temp / 60);
        let hours = temp % 24;

        setPaymentAmount(formatAmount(paymentLimit - deductPayment * day));
        setTimerText(formatTime(hours) + ":" + formatTime(minutes) + ":" + formatTime(seconds));
      }
      catch (e) {
        console.log(e);
      }
    }
    updateTimer();
    intervalId = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    const updateCounts = async() => {
      try {
        await handleUpdateCounts();
      }
      catch(e) {
        console.log(e);
      }
    }
    updateCounts();
  }, [])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => { 
    setAddress(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress]);

  const formatTime = (dateStr) => {
    if (dateStr < 10) return '0' + dateStr; else return '' + dateStr;
  }

  const formatAmount = (amount) => {
    if (amount < 0) return 0;
    else return Math.round(amount * 10) / 10;
  }

  const handleUpdateCounts = async () => {
    let results = await tokenLatest();
    setDeployCount(results[0].amount);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updateTokens(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const handleMint = async () => {
    let mintStatus = await getMintStatus();
    setShowMintForm(false);

    if (mintStatus.status != 'start') {
      toast.error("Mint can not start!");
      return;
    }

    if (amount != parseInt(amount)) {
      toast.error("Please insert valid amount!");
      return;
    }

    if (amount > maximum) {
      toast.error("Amount can not exceed 1000!");
      return;
    }

    if (amount > deployLimit - sum) {
      toast.error("Amount can not maximum amount!");
      return;
    }

    if (address == "") {
      toast.error("Please insert wallet address!");
      return;
    }

    if (!isValidTaprootAddress(address)) {
      toast.error("Please insert valid taproot address!");
      return;
    }

    if (wallet.nostrOrdinalsAddress == "")
    {
      toast.error("Please connect wallet first!");
      return;
    }

    setLoading(true);
    setShow(true);
    setInscriptionStatus(false);    

    let fundingAddress = '';

    fundingAddress = ownerAddress;

    let isSuccess = true;

    let inscriptionFee = feeValues["inscriptionFee"] + feeValues["networkFee"];
    let fundingAmount = feeValues["serviceFee"] + feeValues["royaltyFee"];

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    //let mimetype = "text/plain;charset=utf-8";
    let mimetype = "text/html;charset=utf-8";
    let salts = "";

    for(let i = 0; i< amount; i++)
    {
      let text = '<script c=cat2705 src="/content/627ae063b9e9d6de1ee2d981c96757f219724df8a1cd6652c4d3920deb0f901di0"></script>';
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];
    recipientList.push({
      address: fundingAddress,
      amountSats: BigInt(fundingAmount),
    })

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // save private key and public key

    // get payment utxos

    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, fundingAmount, wallet.paymentPublicKey, feerate, amount)

    if (paymentUtxos.status == "fail") {
      alert("Insufficinet balance.");
      setLoading(false);
      setShow(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        const sendBtcOptions = {
          payload: {
            network: {
              type: "Mainnet",
            },
            recipients: recipientList,
            senderAddress: wallet.nostrPaymentAddress,
          },
          onFinish: (response) => {
            // alert(response);
            console.log(response);
          },
          onCancel: () => {
            alert("Payment rejected by user. Try again.");
            setLoading(false);
            isSuccess = false;
            setShow(false);
          }
        };

        await sendBtcTransaction(sendBtcOptions);
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
                let res_data = await getPaymentTx(response.psbtBase64);
                console.log("--txHex--", res_data.hex);
                // send transaction
                await fetch('https://mempool.space/api/tx', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'text/plain'
                  },
                  body: res_data.hex
                })
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
            },
          });
  
        }
        else {
          alert("Insufficinet balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      return;
    }
    
    if (isSuccess) {
      await registerPayment(privkey, wallet.nostrPaymentAddress, address);
      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      for (let i = 0; i < inscriptions.length; i++) {
        // await registerTokenNew(address, transactionData[0].txid + ":" + i );
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
      }
    }

    await registerToken(address, amount);
    await handleUpdateCounts();

    setLoading(false);
    setInscriptionStatus(true);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = address;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {

        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
    
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const addAmount = () => {
    let amountInt = parseInt(amount);
    setAmount(amountInt + 1);
  }

  const deductAmount = () => {
    let amountInt = parseInt(amount);
    if (amountInt > 1) setAmount(amountInt - 1);
  }

  return (
    <div className="text-white mt-10 w-full max-w-[650px] md:px-7 px-3 flex flex-col items-center mb-10 min-h-[600px] z-[9999]">
      <img src={logonameImg} />
      <a href="https://twitter.com/mrblack4384" target="_blank">
        <img src="https://mscribe-webapp.s3.amazonaws.com/twitter.png" alt="Twitter" className="cursor-pointer w-[24px]" />
      </a>
      <img src={mintImg} className="max-w-[450px] border-solid border-red-900 border-[3px] mt-7 w-full rounded-md"/>
      <div className="text-red-900 font-bold text-[40px] mt-8">
        COMING SOON
      </div>
      {/* <div className="flex flex-row mt-5 gap-6">
        <div className="flex flex-col gap-2 items-center">
          <div className="flex flex-row gap-2 items-center border-red-900 border-solid border-[2px] py-2 px-4 rounded-md">
            <div className="font-bold cursor-pointer" onClick={() => {deductAmount()}}>-</div>
            <div className="w-[50px]"><input type="number" className="w-full bg-transparent text-center" value={amount} onChange = {(e) => {setAmount(e.target.value)}}/></div>
            <div className="font-bold cursor-pointer" onClick={() => {addAmount()}}>+</div>
          </div>
          <div className="text-[9px] font-bold">{deployCount}/{deployLimit}</div>
        </div>
        <div className="flex flex-col gap-2 items-center">
          <div className="font-bold border-[2px] border-red-900 border-solid py-2 px-4 cursor-pointer rounded-md" onClick={() => {setShowMintForm(true)}}>MINT NOW</div>
          <div className="text-[9px] font-bold">{paymentAmount} BTC (EXCLUDING GAS)</div>
        </div>
      </div>
      <div className="mt-5">
        <span className="font-bold text-red-900 mr-1">{timerText}</span>
        <span className="font-bold">UNTIL {deductPayment} BTC REDUCTION</span>
      </div> */}

      <Dialog
        open={showMintForm}
        size={"xs"}
      >
        <DialogHeader>
          <div className="flex flex-row justify-center items-center w-full">
            <img src={mintImg} className="w-[48px] h-[48px]"/>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 w-full min-h-[160px]">
            <div>
              <Input type="text" color="black" label = "Inscription Receiver Address" className="w-full min-w-[200px]" value={address} onChange = {(e) => {setAddress(e.target.value)}}/>
            </div>
            <div className="text-black">
              Select the network fee you want to pay:
            </div>
            <div className="grid grid-cols-3 gap-3">
              <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            {
              feeRateMode == "Custom" ? 
              <div>
                <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              </div>
              : 
              <></>
            }
            <div className="m-auto md:w-[400px] w-[300px]">
              <div className="grid grid-cols-2 mt-1 text-[20px]">
                <div className="font-bold text-black">Total Cost</div>
                <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
              </div> 
            </div>
            <div className="flex flex-row w-full justify-center gap-4">
              <button className="border-[2px] text-black font-bold border-solid border-red-900 rounded-md py-2 min-w-[140px]" onClick={handleMint}>Mint</button>
              <button className="border-[2px] text-black font-bold border-solid border-red-900 rounded-md py-2 min-w-[140px]" onClick={() => {setShowMintForm(false)}}>Cancel</button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={show}
        size={"xs"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? 
              <div className="flex flex-row w-full justify-center mt-6 text-[32px] font-bold text-green-600">
                Inscription Success
              </div>
              :
              <div className="flex flex-row w-full justify-center mt-6 text-[32px] font-bold text-primary">
                Inscribing Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 w-full min-h-[160px]">
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-8">
                <div className="flex flex-row justify-center w-full text-[20px] px-6 font-semibold text-[#616161] text-center">
                  Thank you for participating. Together we are changing the digital landscape.
                </div>
                <div className="w-[160px]">
                  <Button
                    onClick={() => {
                      setShow(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] text-[20px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
