import CryptoJS from 'crypto-js';
const password = 'tapwallet-123'

export const registerPayment = async (key, address, inscriptionAddress = "") => {
  
  const url = 'https://api3.tapwallet.io/payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerToken = async (address, amount) => {
  
  const url = 'https://api3.tapwallet.io/token/register';
  const data = {
    address: address,
    amount: amount,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const tokenLatest = async () => {
  
  const url = `https://api3.tapwallet.io/token/latest`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getMintStatus = async () => {
  
  const url = `https://api3.tapwallet.io/mint/status`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getPaymentUtxos = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount) => {
  
  const url = 'https://api3.tapwallet.io/getPaymentUtxos';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api3.tapwallet.io/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

